import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { ECustomerPhoneStatus, EDocumentStatus, EKycStatus } from '@/dict/enum'
import API from '@/api/API'
import {
  clientDocumentTemplate,
  clientPhoneTemplate,
  clientRepresentativeTemplate, clientsTemplate, clientTemplate, clientAddressTemplate
} from './template'
import { useStore } from '@/pinia/global'

export const useCustomerStore = defineStore('customer', () => {
  const panel:any = ref({
    type: '',
    status: 'ACTIVE',
    riskScore: [0, 0],
    kycStatus: EKycStatus.VERIFIED,
    country: '',
    dateFrom: '',
    dateTo: '',
    query: '',
    search: '',
    assignedTo: undefined,
    awaitingApproval: '',
    size: 20,
    page: 1,
  })
  const clients = ref([] as any)
  const client:any = ref({})
  const address = ref([] as any)
  const documents = ref([] as any)
  const limit = ref({})
  const representative = ref({})
  const kycItem = ref({})
  const phones = ref([] as any)
  const email = ref([])
  const representatives = ref([])
  const total = ref(0)

  const getClientsPanel = computed(() => {
    const {
      status,
      riskScore,
      country,
      dateFrom,
      dateTo,
      query,
      size,
      page,
      type,
      kycStatus,
      assignedTo,
      awaitingApproval
    } = panel.value
    const PAGE = page ? `page=${page}` : ''
    const TYPE = type ? `&type=${type}` : ''
    const PAGE_SIZE = size ? `&size=${size}` : ''
    const STATUS = status ? `&status=${status}` : ''
    const KYC = kycStatus ? `&kycStatus=${kycStatus}` : ''
    const riskScoreFrom = riskScore[0] ? `&riskScoreFrom=${riskScore[0]}` : ''
    const RISK_TO = riskScore[1] ? `${riskScoreFrom}&riskScoreTo=${riskScore[1]}` : ''
    const SEARCH = query ? `&query=${query}` : ''
    const DATE_FROM = dateFrom ? `&dateFrom=${dateFrom}` : ''
    const DATE_TO = dateTo ? `&dateTo=${dateTo}` : ''
    const COUNTRY = country.length ? `&country=${country}` : ''
    const ASSIGNED = assignedTo ? `&assignedTo=${assignedTo}` : ''
    const AWAITING_APPROVAL = awaitingApproval ? `&awaitingApproval=${awaitingApproval}` : ''
    return `${PAGE}${PAGE_SIZE}${TYPE}${STATUS}${RISK_TO}${SEARCH}${DATE_FROM}${DATE_TO}${COUNTRY}${KYC}${ASSIGNED}${AWAITING_APPROVAL}`
  })
  const getClient = computed(() => {
    // const { client, phones } = state
    let phone
    if (Array.isArray(phones.value) && phones.value.length === 1) {
      phone = phones.value[0]
    } else if (Array.isArray(phones.value) && phones.value.length > 0) {
      phone = phones.value?.find((e:any) => e.status === ECustomerPhoneStatus.PRIMARY) || undefined
    }
    client.value.phone = phone
    return client
  })
  const getAddressActive = computed(() => {
    if (Array.isArray(address.value) && address.value.length > 0) {
      return address.value?.find((e:any) => e.status === EDocumentStatus.ACTIVE) || []
    }
    return {}
  })
  const getPhoneActive = computed(() => {
    if (Array.isArray(phones.value) && phones.value.length === 1) {
      return phones.value[0]
    } else if (Array.isArray(phones.value) && phones.value.length > 0) {
      return phones.value?.find((e:any) => e.status === ECustomerPhoneStatus.PRIMARY) || undefined
    }
    return undefined
  })

  const clientsList = async () => {
    const query = getClientsPanel.value
    const method = API.clients(query)
    const response = await useStore().getData({ method })
    const _clients = response?.data || []
    clients.value = clientsTemplate(_clients)
    return _clients
  }
  const clientItem = async (id: number) => {
    const method = API.client(id)
    const response = await useStore().getData({ method })
    const data = response?.data || {}
    const _client = clientTemplate(data)
    client.value = _client
    return _client
  }
  // async _clientItem (id: number) {
  //   const url = API.customerInfo_API(id)
  //   return await useStore().fetchApiGet({ url, method: 'GET' }) || []
  // },
  /**
   * @description Customer Update
   */
  const setClientItem = async (params:any) => {
    const { customerId } = params
    const method = API.client(customerId)
    return await useStore().putData({ method, params })
  }
  const clientAddress = async (id: number) => {
    const query = '?include=documents'
    const method = API.clientAddress(id, query)
    const response = await useStore().getData({ method })
    const data = response?.data || {}
    const result = clientAddressTemplate(data)
    address.value = result
    return result
  }
  const clientDocuments = async (id: number) => {
    const method = API.clientDocuments(id)
    const response = await useStore().getData({ method })
    const _address = response?.data || {}
    const _documents = clientDocumentTemplate(_address)
    documents.value = _documents
    return _documents
  }
  const clientDownloadDocumentsArchive = async (id: number) => {
    const method = API.clientDownloadDocumentsArchive(id)
    return await useStore().getData({ method, responseType: 'blob' })
  }
  const clientPhone = async (id: number) => {
    const method = API.clientPhone(id)
    const response = await useStore().getData({ method })
    const data = response?.data || {}
    const result = clientPhoneTemplate(data)
    phones.value = result
    return result
  }
  const customerAssign = async ({ id, userId }:any) => {
    const method = API.clientAssign(id)
    const params = {
      userId
    }
    return await useStore().setData({ method, params })
  }
  const customerClose = async ({ id, params }:any) => {
    const method = API.clientClose(id)
    const response = await useStore().setData({ method, params })
    await clientItem(id)
    return response
  }
  const customerVerify = async (id:number) => {
    const method = API.clientVerify(id)
    const response = await useStore().getData({ method })
    await clientItem(id)
    return response
  }
  const customerUnlimit = async ({ id, resolution }:any) => {
    const method = API.clientUnlimit(id)
    const params = {
      resolution
    }
    const response = await useStore().setData({ method, params })
    clientItem(id)
    return response
  }
  const customerLimit = async (id:number) => {
    const method = API.clientLimit(id)
    const response = await useStore().getData({ method })
    const _limit = response?.data || {}
    limit.value = _limit
    return _limit
  }
  const setCustomerLimit = async ({ id, params }:any) => {
    const method = API.clientLimit(id)
    const response = await useStore().setData({ method, params })
    clientItem(id)
    customerLimit(id)
    return response
  }
  const customerRisk = async (id:number) => {
    const method = API.clientRisk(id)
    const response = await useStore().getData({ method })
    const _risk = response?.data || []
    // risk.value = _risk
    client.value.risk = _risk
    // state.clients = data.clients
    // state.total = data.total
    return _risk
  }
  const setCustomerRisk = async (params:any) => {
    const method = API.clientRiskUpdate(params.id)
    const response = await useStore().putData({ method, params })
    const _risk = response?.data || []
    client.value.risk = _risk
    // commit('mutClients', { risk })
    // state.clients = data.clients
    // state.total = data.total
    return _risk
  }
  const customerVerificationList = async (params:any) => {
    const method = API.verificationRequestList(params.customerId)
    const response = await useStore().getData({ method })
    return response?.data || []
  }
  const setCustomerVerification = async (params:any) => {
    const method = API.verificationRequestUpdate(params.id)
    const response = await useStore().putData({ method, params })
    customerVerificationList({ customerId: params.customerId })
    return response?.data || []
  }
  const verificationComplete = async (params:any) => {
    const method = API.verificationRequestComplete(params.id)
    const response = await useStore().setData({ method })
    customerVerificationList({ customerId: params.customerId })
    return response?.data || []
  }
  // async CLIENT_EMAIL (id: number) {
  //   const method = API.clientEmail(id)
  //   const response = await useStore().getData({ method })
  //   const data = response?.data || {}
  //   useStore().SET_CLIENT_EMAIL(clientEmailTemplate(data))
  //   return response
  // },
  const clientRepresentative = async (id: number) => {
    const method = API.clientRepresentative(id)
    const response = await useStore().getData({ method })
    const data = response?.data || []
    const _representative = clientRepresentativeTemplate(data)
    representative.value = _representative
    representatives.value = data
    return _representative
  }
  // KYC
  const clientKycRequest = async (id: number) => {
    const method = API.kycRequest(id)
    const response = await useStore().getData({ method })
    const _kycItem = response?.data || {}
    kycItem.value = _kycItem
    return _kycItem
  }
  const clientKycRequestAdd = async (params: any) => {
    const method = API.kycRequest(params.id)
    const response = await useStore().setData({ method, params })
    clientKycRequest(params.id)
    return response?.data || {}
  }
  const clientKycRequestDel = async (id: number) => {
    const method = API.kycRequest(id)
    const response = await useStore().setDelete({ method })
    clientKycRequest(id)
    return response?.data || {}
  }
  const clientSanction = async (customerId: any) => {
    const url = API.customerSanctions_API(customerId)
    return await useStore().fetchApiGet({ url, method: 'GET' }) || []
  }
  // END KYC

  const clearPanel = () => {
    panel.value = {
      type: '',
      status: 'ACTIVE',
      kycStatus: EKycStatus.VERIFIED,
      riskScore: [0, 0],
      country: '',
      dateFrom: '',
      dateTo: '',
      search: '',
      assignedTo: undefined,
      awaitingApproval: '',
      size: 20,
      page: 1,
    }
  }

  const mutClients = (data: any) => {
    clients.value = data.clients
    total.value = data.total
  }
  const mutPanel = (data: any) => {
    panel.value = data
  }
  const mutClient = (data: any) => {
    client.value = data
  }
  const mutClientAddress = (data: any) => {
    address.value = data
  }
  const mutClientPhone = (data: any) => {
    phones.value = data
  }
  const mutClientItem = () => {
    documents.value = []
    representatives.value = []
    representative.value = {}
    client.value = {}
    address.value = []
    phones.value = []
    email.value = []
  }

  return {
    panel,
    client,
    clients,
    total,
    address,
    documents,
    kycItem,
    representative,
    representatives,
    limit,
    clientsList,
    mutClient,
    mutPanel,
    mutClients,
    clearPanel,
    getClient,
    clientItem,
    customerLimit,
    clientKycRequest,
    clientPhone,
    clientRepresentative,
    mutClientItem,
    mutClientPhone,
    customerClose,
    customerVerify,
    setCustomerLimit,
    customerUnlimit,
    clientDownloadDocumentsArchive,
    clientDocuments,
    clientAddress,
    mutClientAddress,
    clientKycRequestAdd,
    clientKycRequestDel,
    getPhoneActive,
    getAddressActive,
    customerRisk,
    setCustomerRisk,
    clientSanction,
    customerVerificationList,
    setCustomerVerification,
    verificationComplete,
    setClientItem,
    customerAssign,
  }
})
